import React from 'react';
import { Link } from 'gatsby';

import links from '../../utils/links';
import useLanguage from '../../hooks/useLanguage';
import enMessages from '../../i18n/navbar/en.json';
import ruMessages from '../../i18n/navbar/ru.json';

import InsuranceLogo from '../../components/InsuranceLogo';
import Rectangle from '../../components/Rectangle';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const currentYear = new Date().getFullYear();

const RoadMap = ({ locale }) => {
  const [currentLanguage] = useLanguage();
  const prefixUrl = React.useMemo(
    () => ((currentLanguage || 'ru') === 'en' ? '/en' : ''),
    [currentLanguage],
  );

  return (
    <div className="RoadMap">
      <div className="RoadMap-Row">
        <div className="RoadMap-Cell RoadMap-Cell_type_aboutUs">
          <div className="RoadMap-CellTitle">
            {messages[locale].businessSolutions}
          </div>
          <div className="RoadMap-GroupLinksLists">
            <ul className="RoadMap-LinksVertical">
              <li>
                <Link to={`${prefixUrl}/products/payouts`}>
                  {messages[locale].payouts}
                </Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/products/payments`}>
                  {messages[locale].onlinePayments}
                </Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/products/online-banking`}>
                  {messages[locale].onlineBank}
                </Link>
              </li>
              {locale === 'ru' && (
                <>
                  <li>
                    <Link to={`${prefixUrl}/products/escrow-account`}>
                      {messages[locale].escrowAccount}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${prefixUrl}/products/marketplaces`}>
                      {messages[locale].splitPaymentForMarketplaces}
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <ul className="RoadMap-LinksVertical">
              {locale === 'ru' && (
                <li>
                  <Link target="_blank" to="/self-employed">
                    {messages[locale].forSelfEmployed}
                  </Link>
                </li>
              )}
              <li>
                <Link to={`${prefixUrl}/products/account`}>
                  {messages[locale].bankAccount}
                </Link>
              </li>
              {locale === 'en' && (
                <li>
                  <Link to={`${prefixUrl}/products/escrow-account`}>
                    {messages[locale].escrowAccount}
                  </Link>
                </li>
              )}
              {locale === 'ru' && (
                <>
                  <li>
                    <Link to={`${prefixUrl}/products/c2c`}>
                      {messages[locale].splitPaymentForC2CServices}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="RoadMap-Cell RoadMap-Cell_type_transfers">
          {locale === 'en' && (
            <>
              <div className="RoadMap-CellTitle">
                {messages[locale].splitPayment}
              </div>
              <ul className="RoadMap-LinksVertical">
                <li>
                  <Link to={`${prefixUrl}/products/marketplaces`}>
                    {messages[locale].splitPaymentForMarketplaces}
                  </Link>
                </li>
                <li>
                  <Link to={`${prefixUrl}/products/c2c`}>
                    {messages[locale].splitPaymentForC2CServices}
                  </Link>
                </li>
              </ul>
            </>
          )}
          {locale === 'ru' && (
            <>
              <div className="RoadMap-CellTitle">
                {messages[locale].moneyTransfer}
              </div>
              <ul className="RoadMap-LinksVertical">
                <li>
                  <Link
                    to={`${prefixUrl}/money-transfer`}
                    dangerouslySetInnerHTML={{
                      __html: messages[locale].moneyTransferToTurkey,
                    }}
                  />
                </li>
                <li>
                  <Link
                    to={`${prefixUrl}/rules_documents_dengi_ru/registration`}
                    dangerouslySetInnerHTML={{
                      __html: messages[locale].rulesDocumentsDengiRu,
                    }}
                  />
                </li>
              </ul>
            </>
          )}
        </div>

        <div className="RoadMap-Cell RoadMap-Cell_type_instructions">
          <div className="RoadMap-Cell">
            <div className="RoadMap-CellTitle">
              {messages[locale].developers}
            </div>
            <ul className="RoadMap-LinksVertical">
              <li>
                <a
                  href={
                    (currentLanguage || 'ru') === 'en'
                      ? links.en.api
                      : links.ru.api
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  API
                </a>
              </li>
            </ul>
          </div>
          {locale === 'ru' && (
            <div className="RoadMap-Cell">
              <div className="RoadMap-CellTitle">
                {messages[locale].instructions}
              </div>
              <ul className="RoadMap-LinksVertical">
                <li>
                  <a
                    href="https://manual.131.ru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages[locale].workingWithInternetBanking}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="RoadMap-Cell RoadMap-Cell RoadMap-Cell_type_aboutUs">
          <div className="RoadMap-CellTitle">{messages[locale].company}</div>
          <div className="RoadMap-GroupLinksLists">
            <ul className="RoadMap-LinksVertical">
              <li>
                <Link to={`${prefixUrl}/about`}>
                  {messages[locale].aboutBank}
                </Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/pricing`}>
                  {messages[locale].pricing}
                </Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/careers`}>
                  {messages[locale].vacancy}
                </Link>
              </li>
            </ul>
            <ul className="RoadMap-LinksVertical">
              <li>
                <Link to={`${prefixUrl}/faq`}>{messages[locale].faq}</Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/blog`}>{messages[locale].blog}</Link>
              </li>
              <li>
                <Link to={`${prefixUrl}/legal/requisite`}>
                  {messages[locale].legalInformation}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="RoadMap-Cell RoadMap-Cell_type_contacts">
          <div className="RoadMap-Cell RoadMap-Cell_type_contacts_item">
            <div className="RoadMap-CellTitle">{messages[locale].contactUs}</div>

            <ul className="RoadMap-LinksVertical">
              <li className="RoadMap-Phone">
                <span className="RoadMap-PhoneNumber">
                  {messages[locale].phoneNumber}
                </span><br />
                <span className='RoadMap-AdditionalInfo'>
                  {messages[locale].phoneNumberDescription}
                </span>
              </li>
              <li>
                <a href="mailto:tech-support@131.ru">
                  {messages[locale].techSupport}: tech-support@131.ru
                </a>
              </li>
            </ul>
          </div>
          <div className="RoadMap-Cell RoadMap-Cell_type_contacts_item">
            <div className="RoadMap-CellTitle">{messages[locale].contactsForDirectingApplicationsToTheBank}</div>
            
            <ul className="RoadMap-LinksVertical">
              <li>
                <span>
                  {messages[locale].address}: {messages[locale].officeAddress} <br />
                </span>
              </li>
              <li>
                <a href="mailto:help@131.ru">
                  {messages[locale].email}: help@131.ru
                </a>
              </li>
              <li>
                <span>
                  {messages[locale].additionalWayToSubmitRequests}{' '}
                  <span className="RoadMap-NoUnderlineLink">
                    <Link to={`${prefixUrl}${messages[locale].onTheWebsiteLink}`} state={{ signUpFosFormOpened: true }}>
                    &#34;{messages[locale].feedback}&#34;
                    </Link>
                  </span>
                </span>
              </li>
            </ul>
            </div>
          </div> 
        </div>

      {locale === 'ru' && (
        <>
          <Rectangle
            config={{
              borderBottom: 'footer',
            }}
          />

          <div className="RoadMap-Insurance">
            <InsuranceLogo className="Insurance-Logo" />

            <div className="Insurance-Title">
              {messages[locale].insuranceInfo}
            </div>
            <Link
              to={`/${prefixUrl}legal/infoRates`}
              className="Insurance-SubTitle"
            >
              {messages[locale].insuranceInfoLinkText}
            </Link>
          </div>
        </>
      )}

      <div className="RoadMap-Description">
        {messages[locale].copyright.replace(
          /\{\{\s*currentYear\s*\}\}/i,
          currentYear,
        )}
      </div>
    </div>
  );
};

export default RoadMap;
